// import useService from '@banyudu/use-service'
import {
  getTopCreations,
  // getTopCreationsSegment,
} from '@/service/creation.service'
import useInfinite from './useInfinite'
import useAuthedService from './useAuthService'

const useTopCreations = useAuthedService(getTopCreations)

// const useTopCreationsSegment = useService(getTopCreationsSegment)

export const useInfiniteTopCreations = useInfinite(useTopCreations, {
  rowKey: 'creation_id',
})

// const useInfiniteTopCreationsSegment = useInfiniteSegment(
//   useTopCreationsSegment,
//   {
//     rowKey: 'creation_id',
//   },
// )

// export default useTopCreations
