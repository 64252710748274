'use client'

import SearchInput from '@/components/search-input'
import { useCallback, useEffect, useMemo, useState } from 'react'
import IconPolygon from '@/public/assets/polygon.svg'
import TemplateCard from './card'
import Button from '@/components/button'
import { cls, whisper } from '@/utils'
import { useCachedAllTemplates, useCachedTemplates } from '@/hooks/useTemplates'
import useSearchTemplates from '@/hooks/useSearchTemplates'
import { SearchTemplateResultItem, Template, TemplateCategory } from '@/types'
import { useRouter } from 'next/navigation'
import { useCachedTemplateCategories } from '@/hooks/useTemplateCategories'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import TemplateIcon from '../creation-input/creation-mode/icon'
import { useCachedHotTemplates } from '@/hooks/useHotTemplates'

export interface TemplatesExploreProps {
  className?: string
}

const mobileVisibleItems = 4
const desktopVisibleItems = 8

const maxVisibileItems = Math.max(mobileVisibleItems, desktopVisibleItems)

export default function TemplatesExplore({ className }: TemplatesExploreProps) {
  const { data: templateCategories } = useCachedTemplateCategories()
  const { data: hotTemplates } = useCachedHotTemplates()
  const { data: allTemplates } = useCachedAllTemplates()

  // const [activeKey, setActiveKey] = useState(templateCategories?.[0]?.category)
  const [activeCategoryKey, setActiveCategoryKey] = useState('hot')
  const { isBelowMd } = useBreakpoint('md')

  const displayTemplatesCategories: TemplateCategory[] = useMemo(() => {
    return [
      ...(hotTemplates?.records?.length
        ? [{ category: 'hot', label: 'Hot', icon: 'https://scontent.haiper.ai/webapp/images/templates/hot.svg' }]
        : []),
      ...(templateCategories ?? []),
    ]
  }, [templateCategories, hotTemplates])

  const queryTemplateParams = useMemo(
    () => ({
      category: activeCategoryKey ?? '',
      limit: maxVisibileItems + 1,
    }),
    [activeCategoryKey],
  )

  const router = useRouter()
  // const { data: templatesRes } = useInfiniteTemplates(queryTemplateParams)
  const { data: templatesRes } = useCachedTemplates(queryTemplateParams)

  const staleTemplates = useMemo(() => {
    return allTemplates?.records?.filter((item) => item.category === activeCategoryKey)?.splice(0, maxVisibileItems)
  }, [allTemplates, activeCategoryKey])

  const templates: Template[] = useMemo(() => {
    const result =
      activeCategoryKey === 'hot'
        ? [...(hotTemplates?.records ?? [])]
        : [...(templatesRes?.records ?? staleTemplates ?? [])]
    return result.splice(0, isBelowMd ? mobileVisibleItems : desktopVisibleItems)
  }, [templatesRes, isBelowMd, activeCategoryKey, hotTemplates, staleTemplates])

  const hasMoreTemplates = useMemo(() => {
    const visibleCount = isBelowMd ? 2 : 4
    // currently there's no total_size field in the BE response, so we fetch one more item to check if there's more
    const fetched = templatesRes?.records?.length ?? 0
    return fetched > visibleCount
  }, [templatesRes, isBelowMd])

  useEffect(() => {
    if (templateCategories?.length && !activeCategoryKey) {
      setActiveCategoryKey(templateCategories[0]?.category)
    }
  }, [templateCategories, activeCategoryKey])

  const activeCategory = useMemo(() => {
    return templateCategories?.find(({ category }) => category === activeCategoryKey) ?? null
  }, [templateCategories, activeCategoryKey])

  const handleGotoCategory = useCallback(() => {
    if (activeCategory) {
      // router.push(`/templates/category/${activeCategory.category}`)
      router.push(`/templates?category=${activeCategory.category}`)
    }
  }, [activeCategory, router])

  const activeCategoryName = useMemo(() => {
    return activeCategoryKey === 'hot' ? 'Hot' : activeCategory?.label
  }, [activeCategory, activeCategoryKey])

  const handleGroupClick = useCallback((key: string) => {
    setActiveCategoryKey(key)
  }, [])

  const [keyword, setKeyword] = useState('')

  const searchParams = useMemo(() => {
    return {
      query: keyword,
    }
  }, [keyword])
  const { data: searchResult } = useSearchTemplates(searchParams)

  const openTemplateDetail = useCallback(
    (templateId: string) => {
      router.push(`/template/${templateId}`)
    },
    [router],
  )

  return (
    <div className={cls('flex flex-col gap-4 w-full', className)}>
      <div className='text-2xl font-bold tracking-45 hidden md:block'>Templates powered by AI</div>
      <div
        className='rounded-xl px-4 pt-6 flex flex-col items-center gap-4 md:gap-6 text-text-on-color bg-gradient-to-r from-band-500 to-band-700'
        aria-label='search'
      >
        <div className='flex flex-col gap-2 w-full md:w-[600px]' aria-label=''>
          <span className='text-body-lg tracking-32'>Start creation from Templates</span>
          <SearchInput
            className='text-text w-full'
            placeholder='Search for Templates'
            defaultValue={keyword}
            dropdown={
              searchResult ? (
                <div className='bg-surface rounded-md shadow-md w-full'>
                  {searchResult?.map(
                    ({ name, introduction, cover_image, template_id }: SearchTemplateResultItem, index: number) => {
                      return (
                        <div
                          key={[template_id, index].join(',')}
                          className='flex gap-2.5 px-4 py-2 border-b border-border cursor-pointer'
                          onClick={() => openTemplateDetail(template_id)}
                        >
                          <div className='aspect-video overflow-hidden shrink-0 w-19 h-12'>
                            <img
                              src={cover_image}
                              alt={name}
                              className='aspect-video w-full h-full object-cover rounded-md'
                            />
                          </div>
                          <div className='flex flex-col gap-1 flex-1'>
                            <span className='text-heading-md font-bold tracking-32'>{name}</span>
                            <span className='text-body-md tracking-15'>{introduction}</span>
                          </div>
                        </div>
                      )
                    },
                  )}
                </div>
              ) : undefined
            }
            onChange={setKeyword}
          />
        </div>
        <div className='flex items-center px-3 gap-10 md:gap-6 max-w-full overflow-x-auto overflow-y-hidden mx-auto'>
          {displayTemplatesCategories?.map(({ category, label, icon }) => {
            const isActive = activeCategoryKey === category
            return (
              <div
                key={category}
                className='relative flex flex-col items-center gap-1 pb-6 cursor-pointer w-10 md:w-22 shrink-0'
                onClick={() => {
                  handleGroupClick(category)
                }}
              >
                <div className='flex justify-center items-center size-10 md:size-12 bg-white rounded-full hover:opacity-80'>
                  <TemplateIcon
                    src={icon}
                    alt='template category logo'
                    className='size-10 md:size-12 border-0 border-none'
                    iconClassName='size-[27px] md:size-8'
                  />
                </div>
                <span className='text-body-sm md:text-body-md md:font-medium tracking-15 whitespace-nowrap'>
                  {label}
                </span>
                {/* selected bottom arrow */}
                {isActive && (
                  <div className='absolute bottom-0 inset-x-0 flex items-center justify-center'>
                    <IconPolygon className='size-6 translate-y-2 text-surface' />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div
        className='w-full md:flex md:justify-center rounded-xl bg-surface md:bg-surface-subdued flex flex-col gap-2 md:p-4'
        aria-label='list'
      >
        <span className='text-heading-md font-bold tracking-32'>Templates - {activeCategoryName}</span>
        <div className='grid grid-cols-2 md:grid-cols-4 gap-3' aria-label='gallery'>
          {templates.map((template) => {
            return <TemplateCard key={template?.template_id} data={template} />
          })}
        </div>
        {hasMoreTemplates && (
          <div className='w-full flex items-center justify-center'>
            <Button
              variant='outline'
              className='bg-surface hover:bg-surface hover:border-border-hover'
              onClick={handleGotoCategory}
            >
              See more {activeCategory?.label?.toLowerCase()} templates
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
