import ChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import { useCachedSpotlights } from '@/hooks/useSpotlights'
import Link from '@/components/link'
import SpotlightCard, { SpotlightCardRef } from './card'
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { cls, getContentAreaWidth } from '@/utils'
import Empty from '../empty'
import { useResizeObserver, useWindowSize } from 'usehooks-ts'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import useVolume from '@/hooks/useVolume'

const getIndexDistance = (from: number, to: number, total: number) => {
  const distance = (total + to - from) % total
  const negativeDistance = distance - total
  return Math.abs(distance) > Math.abs(negativeDistance)
    ? negativeDistance
    : distance
}

export default function Spotlights() {
  const isDebug = process.env.NODE_ENV === 'development'

  const { data: spotlights, isValidating: spotlightsLoading } =
    useCachedSpotlights()
  const { isBelowMd } = useBreakpoint('md')

  const itemRefs = useRef<Record<string, SpotlightCardRef | null>>({})
  const containerRef = useRef<HTMLDivElement>(null)

  const { width: windowWidth } = useWindowSize() // watch window size

  const { width = 0 } = useResizeObserver({
    ref: containerRef,
    box: 'border-box',
  })
  const maxDistance = isBelowMd ? 2 : 3

  const absoluteStyles = useMemo(() => {
    const containerWidth: number = width || getContentAreaWidth(windowWidth)
    const paddingSize = isBelowMd ? 8 : 16
    const infoHeight = isBelowMd ? 132 : 113

    const perspective = 1000
    const stage1TranslateZ = -500
    const stage2TranslateZ = -450
    const previewPercentage = 0.385
    const rotateY = 45
    // const rotateY = 0
    const stage1ZFactor = (perspective - stage1TranslateZ) / perspective
    const stage2ZFactor = (perspective - stage2TranslateZ) / perspective

    // const yFactor = Math.cos((rotateY * Math.PI) / 180)

    const stage1WidthFactor =
      1 *
      Math.cos((rotateY * Math.PI) / 180) *
      (1 / stage1ZFactor) *
      previewPercentage
    const stage2WidthFactor = isBelowMd
      ? 0
      : 1 *
        Math.cos((rotateY * Math.PI) / 180) *
        (1 / stage2ZFactor) *
        previewPercentage

    const baseWidth =
      containerWidth / (1 + 2 * stage1WidthFactor + 2 * stage2WidthFactor)
    const xBorder = 4
    const yBorder = 6
    const baseHeight =
      infoHeight +
      2 * paddingSize +
      yBorder +
      ((baseWidth - 2 * paddingSize - xBorder) * 9) / 16

    const stage1TranslateX =
      (baseWidth * (1 + stage2WidthFactor * 2) * stage1ZFactor - baseWidth) / 2
    const stage2TranslateX = (containerWidth * stage2ZFactor - baseWidth) / 2

    const commonStyle: CSSProperties = {
      width: `${baseWidth}px`,
      height: `${baseHeight}px`,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }
    const carouselActiveStyle: CSSProperties = {
      ...commonStyle,
      zIndex: 10,
    }
    const carouselPrevSiblingStyle: CSSProperties = {
      ...commonStyle,
      left: 0,
      transform: `translateX(${-stage1TranslateX}px) translateZ(${stage1TranslateZ}px) rotateY(${rotateY}deg)`,
      zIndex: 9,
    }
    const carouselNextSiblingStyle: CSSProperties = {
      ...commonStyle,
      right: 0,
      transform: `translateX(${stage1TranslateX}px) translateZ(${stage1TranslateZ}px) rotateY(-${rotateY}deg)`,
      zIndex: 9,
    }
    const carouselPrevPrevSiblingStyle: CSSProperties = {
      ...commonStyle,
      left: 0,
      opacity: 0.6,
      transformOrigin: 'left center',
      transform: `translateX(${-stage2TranslateX}px) translateZ(${stage2TranslateZ}px) rotateY(${rotateY}deg)`,
      zIndex: 8,
    }
    const carouselNextNextSiblingStyle: CSSProperties = {
      ...commonStyle,
      right: 0,
      opacity: 0.6,
      transformOrigin: 'right center',
      transform: `translateX(${stage2TranslateX}px) translateZ(${stage2TranslateZ}px) rotateY(-${rotateY}deg)`,
      zIndex: 8,
    }
    const carouselFarPrevSiblingStyle: CSSProperties = {
      ...commonStyle,
      left: 0,
      transform: `translateX(${-stage2TranslateX}px) translateZ(${stage2TranslateZ}px) rotateY(${rotateY}deg)`,
      zIndex: 7,
      opacity: 0,
    }
    const carouselFarNextSiblingStyle: CSSProperties = {
      ...commonStyle,
      right: 0,
      transform: `translateX(${stage2TranslateX}px) translateZ(${stage2TranslateZ}px) rotateY(-${rotateY}deg)`,
      zIndex: 7,
      opacity: 0,
    }

    return {
      width: baseWidth,
      height: baseHeight,
      carouselActiveStyle,
      carouselPrevSiblingStyle,
      carouselNextSiblingStyle,
      carouselPrevPrevSiblingStyle,
      carouselNextNextSiblingStyle,
      carouselFarPrevSiblingStyle,
      carouselFarNextSiblingStyle,
    }
  }, [width, isBelowMd, windowWidth])

  const distance2Style: Record<string | number, CSSProperties | undefined> =
    useMemo(() => {
      if (maxDistance === 2) {
        return {
          '-2': absoluteStyles.carouselFarPrevSiblingStyle,
          '-1': absoluteStyles.carouselPrevPrevSiblingStyle,
          0: absoluteStyles.carouselActiveStyle,
          1: absoluteStyles.carouselNextNextSiblingStyle,
          2: absoluteStyles.carouselFarNextSiblingStyle,
        }
      }
      return {
        '-3': absoluteStyles.carouselFarPrevSiblingStyle,
        '-2': absoluteStyles.carouselPrevPrevSiblingStyle,
        '-1': absoluteStyles.carouselPrevSiblingStyle,
        0: absoluteStyles.carouselActiveStyle,
        1: absoluteStyles.carouselNextSiblingStyle,
        2: absoluteStyles.carouselNextNextSiblingStyle,
        3: absoluteStyles.carouselFarNextSiblingStyle,
      }
    }, [absoluteStyles, maxDistance])

  const pauseAll = useCallback(() => {
    for (const spotlightId in itemRefs.current) {
      try {
        itemRefs.current[spotlightId]?.videoRef.current?.pause()
      } catch (error) {
        // do nothing
      }
    }
  }, [])

  const hasSpotlights = spotlights?.length && spotlights?.length > 0

  const thumbWidth = Math.ceil(200 / (spotlights?.length || 1))
  const [activeIndex, setActiveIndex] = useState(0)

  const { muted } = useVolume()

  useEffect(() => {
    if (spotlights?.length) {
      setActiveIndex((old) => {
        return (old ?? 0) === 0
          ? Math.floor(Math.random() * spotlights.length)
          : old
      })
    }
  }, [spotlights?.length])

  const thumbStyle: CSSProperties = useMemo(() => {
    return {
      width: thumbWidth,
      left: thumbWidth * activeIndex,
    }
  }, [thumbWidth, activeIndex])

  const move = useCallback(
    (delta: number) => {
      if (!spotlights?.length) {
        return
      }
      pauseAll()
      setActiveIndex((old) => {
        const newIndex: number =
          (old + delta + spotlights.length) % spotlights.length
        const videoApiRef =
          itemRefs.current[spotlights[newIndex].spotlight_id]?.apiRef
        try {
          if (videoApiRef) {
            videoApiRef.current?.play()
            setTimeout(() => {
              if (muted) {
                videoApiRef.current?.mute()
              } else {
                videoApiRef.current?.unmute()
              }
            }, 0)
          }
        } catch (error) {
          // do nothing
        }
        return newIndex
      })
    },
    [spotlights, pauseAll, muted],
  )

  const moveForward = useCallback(() => {
    move(1)
  }, [move])

  const moveBackward = useCallback(() => {
    move(-1)
  }, [move])

  if (spotlightsLoading) {
    return null
  }

  const setRef = (spotlight: any) => {
    return (item: any) => {
      itemRefs.current[spotlight.spotlight_id] = item
    }
  }

  return (
    <div
      className='pt-8 flex flex-col gap-4 w-full'
      aria-label='spotlights'
      data-testid='spotlights'
    >
      <div
        className='flex justify-between w-full items-center'
        aria-label='toolbar'
      >
        <div className='text-heading-2xl font-bold'>Spotlight</div>
        <Link
          className='flex text-text no-underline hover:no-underline hover:opacity-80'
          aria-label='Spotlight'
          href='/spotlight'
          data-testid='spotlight-view-all'
        >
          <span className='text-body-md tracking-15'>View all</span>
          <ChevronRight className='size-5 text-icon' />
        </Link>
      </div>
      <div
        ref={containerRef}
        // className={cls('flex w-full gap-4 items-center justify-center overflow-hidden', isDebug ? 'border border-red-500' : '')}
        className={cls(
          'flex w-full gap-4 items-center justify-center overflow-hidden',
          isDebug ? '' : '',
        )}
        aria-label='contents'
        style={{
          height: absoluteStyles.height,
          transformStyle: 'preserve-3d',
        }}
      >
        {spotlights?.length ? (
          <div
            className={cls(
              'relative h-full items-center perspective-1000 transition-all transform-gpu duration-500',
            )}
            style={{
              width: absoluteStyles.width,
              WebkitPerspective: 1000,
            }}
          >
            {spotlights?.map((spotlight, index) => {
              const distance = Math.min(
                maxDistance,
                Math.max(
                  -maxDistance,
                  getIndexDistance(activeIndex, index, spotlights.length),
                ),
              )
              if (Math.abs(distance) >= maxDistance) {
                return null
              }
              return (
                <div
                  key={spotlight.spotlight_id}
                  className={cls('absolute transition-transform duration-500')}
                  style={distance2Style[distance]}
                  aria-details={String(distance)}
                >
                  <SpotlightCard
                    ref={setRef(spotlight)}
                    key={spotlight.spotlight_id}
                    inactive={distance !== 0}
                    autoPlay={false}
                    className='cursor-pointer'
                    data={spotlight}
                    onTouchMoveBackward={moveBackward}
                    onTouchMoveForward={moveForward}
                    onClick={() => {
                      if (distance === 0) {
                        return
                      }
                      if (distance < 0) {
                        moveBackward()
                      } else {
                        moveForward()
                      }
                    }}
                  />
                </div>
              )
            })}
          </div>
        ) : (
          <div className='w-full flex justify-center'>
            <Empty />
          </div>
        )}
        <div
          className={cls(
            'relative w-50 h-1 bg-border rounded-full',
            hasSpotlights ? '' : 'hidden',
            'hidden',
          )}
          aria-label='scroll'
        >
          <div
            className={cls('absolute bg-icon-interactive h-full rounded-full')}
            style={thumbStyle}
          />
        </div>
      </div>
    </div>
  )
}
