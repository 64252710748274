import { Template } from '@/types'
import { cls } from '@/utils'
import ChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import { useRouter } from 'next/navigation'
import { useCallback, useState } from 'react'
import Video from '../video'

export interface TemplateCardProps {
  className?: string
  data?: Template
}

export default function TemplateCard({ className, data }: TemplateCardProps) {
  const router = useRouter()
  const canGotoDetail = data?.template_id && !data?.template_id.startsWith('demo:')

  const handleGotoDetail = useCallback(() => {
    // TODO: remove the limit
    // const categoryWhitelist = ['basic_tool', 'dance']
    // if (categoryWhitelist.includes(data?.category ?? '')) {
    //   router.push(`/template/${data?.template_id}`)
    // }
    if (canGotoDetail) {
      router.push(`/template/${data?.template_id}`)
    }
  }, [data, router, canGotoDetail])

  const [coverLoadFailed, setCoverLoadFailed] = useState(false)

  const coverVideo = data?.examples?.[0]?.media_url

  return (
    <div
      className={cls(
        'group rounded-xl md:p-2 bg-surface flex flex-col gap-2 cursor-pointer border-2 border-b-4 border-border hover:border-border-hover',
        canGotoDetail ? 'cursor-pointer' : 'cursor-default',
        className,
      )}
      onClick={handleGotoDetail}
    >
      <div className='relative w-full aspect-video rounded-md overflow-hidden'>
        {coverLoadFailed || (!data?.cover_image && !coverVideo) ? (
          <div className='w-full aspect-video rounded-md overflow-hidden bg-surface-subdued flex items-center justify-center text-body-lg font-medium tracking-32'>
            {data?.name}
          </div>
        ) : coverVideo ? (
          <Video
            loop
            playOnHover
            muted
            playsInline
            src={coverVideo}
            poster={data?.cover_image}
            className='w-full h-full object-scale-down'
            onError={() => setCoverLoadFailed(true)}
          />
        ) : (
          <img
            src={data?.cover_image}
            alt={data?.name}
            className='w-full h-full object-scale-down'
            onError={() => setCoverLoadFailed(true)}
          />
        )}
      </div>
      <div className='flex items-center w-full justify-between h-8 px-1 text-text '>
        <span className='text-body-sm font-medium tracking-15'>{data?.name}</span>
        <ChevronRight className='size-5 text-icon group-hover:text-icon-subdued' />
      </div>
    </div>
  )
}
