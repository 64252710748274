import { CSSProperties, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { cls, preventDefault } from '@/utils'
import IconSearch from '@haiper/icons-svg/icons/outline/search.svg'
import { PoNVoid } from '@/types'
import IconBackspace from '@haiper/icons-svg/icons/outline/x-backspace.svg'
import { Popover, PopoverContent, PopoverAnchor } from '@/components/ui/popover'
import { useWindowSize } from 'usehooks-ts'

export interface SearchInputProps
  extends Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type' | 'disabled' | 'className' | 'defaultValue' | 'placeholder'
  > {
  dropdown?: ReactElement
  onChange?: (value: string) => PoNVoid
}

export default function SearchInput({
  className,
  disabled,
  defaultValue,
  dropdown,
  onChange,
  ...props
}: SearchInputProps) {
  const [value, setValue] = useState(defaultValue)
  const [focused, setFocused] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  const handleChange = useCallback(
    (newValue: string) => {
      setValue(newValue)
      onChange?.(newValue)
    },
    [onChange],
  )

  const dropDownOpen = Boolean(focused && value && dropdown)

  const [dropdownWidth, setDropdownWidth] = useState(0)
  const dropdownContainerStyle: CSSProperties = { width: dropdownWidth || 'auto' }

  const { width: windowWidth } = useWindowSize()
  useEffect(() => {
    setTimeout(() => {
      setDropdownWidth(ref?.current?.clientWidth || 0)
    }, 0)
  }, [windowWidth])

  return (
    <div
      ref={ref}
      className={cls(
        'flex px-4 py-2 gap-2 relative h-10 bg-surface group w-full rounded-md border border-border text-body-md',
        'focus-within:border-border-hover focus-within:shadow-border-hover ring-offset-background placeholder:text-text-subdued focus-visible:outline-none group-focus-visible:ring-border-hover focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        disabled ? 'opacity-50 cursor-not-allowed' : '',
        dropDownOpen && dropdown && 'rounded-b-none',
        className,
      )}
    >
      <IconSearch className='size-6 text-icon' />
      <Popover open={dropDownOpen}>
        <PopoverAnchor className='flex-1 flex'>
          <input
            className='placeholder:text-text-subdued focus:border-0 flex-1 focus-visible:border-0 focus-visible:outline-none bg-surface'
            disabled={disabled}
            autoFocus={false}
            value={value}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={(e) => handleChange(e.target.value)}
            {...props}
          />
        </PopoverAnchor>
        <PopoverContent
          className='p-0 rounded-t-none border-0 border-t max-h-[400px] overflow-y-auto'
          sideOffset={8}
          style={dropdownContainerStyle}
          onOpenAutoFocus={preventDefault}
        >
          {dropdown}
        </PopoverContent>
      </Popover>
      <IconBackspace
        className={cls('cursor-pointer hover:opacity-80 text-icon', value ? 'visible' : 'invisible')}
        onClick={() => handleChange('')}
      />
    </div>
  )
}
